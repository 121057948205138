import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AboutV1 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120 pb-90 ">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl + "assets/img/others/7.png"} alt="About Us Image" />
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Truck Message</h6>
								<h1 className="section-title">Revolutionize Your Lorry Logistics Experience with<span>TRUCK Message</span></h1>
								<p className='para'>Who We Are and What We Stand For</p>
							</div>
							<ul className="ltn__list-item-half clearfix">
								<li>
									<i className="flaticon-deal-1" />
									Ensuring efficiency and profitability for clients
								</li>
								<li>
									<i className="flaticon-expand" />
									Bridging gapsbetween truck owners, drivers, and service providers.
								</li>
								<li>
									<i className="flaticon-key-1" />
									Integrity, transparency, customer-centricity.
								</li>
								<li>
									<i className="flaticon-secure" />
									Experienced trucking and logistics professionals.
								</li>
							</ul>
							<div className="btn-wrapper animated go-top">
								<Link to={process.env.REACT_APP_DYNAMIC_URI} className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
							</div>
						</div>
					</div>
				</div>

				<div className='mt-5 p-3'>


					<p className='mt-3 p-3'>		Welcome to Truck Message, a trade name of Niram Tech Services  your ultimate companion for all things related to trucking, logistics, and transportation. We’re revolutionizing the industry by bringing everything you need into one seamless app. Whether you're managing your fleet, tracking your loads, or handling day-to-day logistics, Truck Message is here to help you save time, reduce costs, and boost your efficiency.

						<h3 className='mt-3'>
							Why Truck Message?
						</h3> With Truck Message, you can streamline your operations with just a few taps. Our app offers a range of services designed to make your trucking experience smoother, smarter, and more profitable:

						<ul className=' px-3 '>
							<li>
								Load & Truck Booking: Quickly book loads or find available trucks that meet your exact needs  no more waiting around.

							</li>
							<li>
								Driver Arrangements: Easily connect with qualified drivers to ensure timely deliveries.
							</li>
							<li>
								Truck Buying & Selling: Search, compare, and finalize truck purchases and sales directly from the app.
							</li>
							<li>
								Truck Parking: Find safe and convenient parking spaces for your trucks, wherever you are.

							</li>
							<li>
								Discounted Fuel: Take advantage of exclusive fuel discounts at our partnered petrol stations.

							</li>
							<li>
								ATM & Restroom Access: Locate ATMs and clean restrooms at petrol stations, ensuring a comfortable journey.

							</li>
							<li>
								Load Tracking: Track your shipments in real-time for complete peace of mind.

							</li>
							<li>
								Toll Gate & Mileage Calculators: Plan your routes more effectively with accurate toll fee estimations and mileage tracking to save on fuel and time.

							</li>
							<li>
								FASTag Recharge: No more worries about toll payments. With our FASTag recharge feature, you can easily top-up your FASTag balance and keep your trips moving smoothly.

							</li>
							<li>
								Commercial Insurance Renewal: Manage your truck’s insurance directly from the app. Renew your commercial vehicle insurance hassle-free with just a few taps and ensure your fleet is always covered.

							</li>
						</ul>

						<h3 className='mt-3 py-3'>
							Secure & Trusted
						</h3>
						Niram Tech Services, through its trade name Truck Message, is in the process of trademark registration to ensure the security and reliability of the services we provide. We’re dedicated to offering innovative and trustworthy solutions that empower your business.
						With Truck Message, you get more than just an app  you get a powerful tool designed to optimize your operations and reduce downtime. From booking and tracking to recharging and renewing, we’ve got everything you need to run your logistics business with ease.
						Join the Truck Message community today, powered by Niram Tech Services, and experience a smarter way to manage your fleet, loads, and logistics needs.



					</p>
				</div>
			</div>
		</div>
	}
}

export default AboutV1